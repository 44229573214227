<template>
    <div>
      <article>
        <section>
          <div class="country_dialogs">
            <el-dialog
              :title="`Update TourIdea`"
              :visible.sync="isEditTourIdeaModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="countryForm"
                  :rules="rules"
                  label-position="top"
                  ref="countryForm"
                  class="demo-countryForm2"
                >
                <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="tourIdeaTitle" prop="tourIdeaTitle">
                        <el-input v-model="countryForm.tourIdeaTitle"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="tourIdea Types" prop="tourIdeaTypeOptions">
                        <el-input textarea v-model="countryForm.tourIdeaTypeOptions"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="tourIdea Amount" prop="tourIdeaAmount">
                        <el-input textarea v-model="countryForm.tourIdeaAmount"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="Tour Idea Inclusions And Exclusions" prop="tourIdeaInclusionsAndExclusions">
                        <el-input textarea v-model="countryForm.tourIdeaInclusionsAndExclusions"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="TourIdea Description" prop="tourIdeaDescription">
                        <el-input textarea v-model="countryForm.tourIdeaDescription"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item label="TourIdea Photo">
                        <el-upload class="upload-demo" drag action="''" :on-change="handleTourIdeaPhotoPreview"
                          :on-remove="handleTourIdeaPhotoRemove" :file-list="tourIdeaPhotoFileLists" :auto-upload="false"
                          :multiple="false">
                          <div v-if="!isUploadingFile">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                              Drop file here or <em>click to upload</em>
                            </div>
                          </div>
                          <div v-else>
                            <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                              :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                            <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                              :stroke-width="4"></el-progress>
                            <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                              {{
                                imageUploadingProgress == 100
                                ? "Uploaded"
                                : "Uploading..."
                              }}
                            </div>
                          </div>
                          <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                        </el-upload>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="editTourIdea('countryForm')"
                    > Save TourIdea Changes</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2024 Bakyala Safaris, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="countryForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        
        selectedTourIdeaPhotoFile: null,
        isUploadingFile: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        tourIdeaPhotoFileLists: [],
        addTourIdeaModal: false,
        isEditTourIdeaModalVisible: false,
        submitting: false,
        countryForm: {
          tourIdeaTitle: "",
          tourIdeaTypeOptions: "",
          tourIdeaAmount: "",
          tourIdeaInclusionsAndExclusions: "",
          tourIdeaDescription: "",

        },
        rules: {
          tourIdeaTitle: [
            {
              required: true,
              message: "TourIdea Name is required",
              trigger: "blur",
            },
          ],
  
          tourIdeaAmount: [
            {
              required: true,
              message: "TourIdea Amount is required",
              trigger: "blur",
            },
          ],
          tourIdeaTypeOptions: [
            {
              required: true,
              message: "TourIdea Type is required",
              trigger: "blur",
            },
          ],
  
          tourIdeaInclusionsAndExclusions: [
            {
              required: true,
              message: "TourIdea Inclusions And Exclusions is required",
              trigger: "blur",
            },
          ],
          tourIdeaDescription: [
            {
              required: true,
              message: "tourIdea Description is required",
              trigger: "blur",
            },
          ],
          
        },
      };
    },
  
    props: {
      showTourIdeaEditDialog: {
        required: true,
        type: Boolean,
      },
      tourIdeaData: {
        required: true,
        type: Object,
      },
    },
  
    watch: {
      showTourIdeaEditDialog() {
        if (this.showTourIdeaEditDialog === true) {
          this.isEditTourIdeaModalVisible = true;
          this.countryForm.tourIdeaTitle = this.tourIdeaData.tourIdeaTitle;
          this.countryForm.tourIdeaID = this.tourIdeaData.tourIdeaID;
          this.countryForm.tourIdeaTypeOptions = this.tourIdeaData.tourIdeaTypeOptions;
          this.countryForm.tourIdeaAmount = this.tourIdeaData.tourIdeaAmount;
          this.countryForm.tourIdeaInclusionsAndExclusions = this.tourIdeaData.tourIdeaInclusionsAndExclusions;
          this.countryForm.tourIdeaDescription = this.tourIdeaData.tourIdeaDescription;
          this.selectedCountryPhotoFile = this.tourIdeaData.tourIdeaImage;
          this.tourIdeaPhotoFileLists = [
            {
              name: "tourIdeaImage",
              url: "this.tourIdeaData.tourIdeaImage",
            },
          ];
        } else {
          this.isEditTourIdeaModalVisible = false;
        }
      },
    },
  
    methods: {
      handleClose(done) {
        done();
        this.$emit("closeEditTourIdeaDialog");
      },
      handleTourIdeaPhotoPreview(file) {
        console.log(file.raw);
        this.tourIdeaPhotoFileLists = [file];
        this.selectedTourIdeaPhotoFile = file.raw;
      },
      handleTourIdeaPhotoRemove() {
        this.selectedTourIdeaPhotoFile = null;
      },
  
      async editTourIdea(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("tourIdeaTitle", this.countryForm.tourIdeaTitle);
              formData.append("tourIdeaAmount", this.countryForm.tourIdeaAmount);
              formData.append("tourIdeaTypeOptions", this.countryForm.tourIdeaTypeOptions);
              formData.append("tourIdeaInclusionsAndExclusions", this.countryForm.tourIdeaInclusionsAndExclusions);
              formData.append("tourIdeaDescription", this.countryForm.tourIdeaDescription);
              formData.append("tourIdeaImage", this.selectedTourIdeaPhotoFile);
              let response = await this.$http.patch(
                `tour-ideas/${this.tourIdeaData.tourIdeaID}`, formData, {
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              }
              );
              if (
                response.data.success &&
                response.data.message == "TOUR_IDEA_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "TourIdea updated successfully",
                  type: "success",
                });
                this.$emit("closeEditTourIdeaDialog");
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to update TourIdea",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  </style>